<template>
  <div style="max-height: 480px; overflow-y: auto">
    <a-table rowKey="uid" :columns="columns" :data-source="dataItems" size="small" :pagination="false">
      <template slot="process" slot-scope="value, item">
        <ProcessSelect
          v-model="item.process"
          placeholder="请选择工序"
          size="small"
          style="width: 100%"
          @change="handleChange"
        />
      </template>
      <template slot="is_critical" slot-scope="value, item">
        <a-select v-model="item.is_critical" size="small" style="width: 100%" @change="handleChange">
          <a-select-option :value="true">是</a-select-option>
          <a-select-option :value="false">否</a-select-option>
        </a-select>
      </template>
      <template slot="preparation_time" slot-scope="value, item">
        <a-input-number v-model="item.preparation_time" :min="0" size="small" style="width: 100%" @change="handleChange" />
      </template>
      <template slot="relate_process_set" slot-scope="value, item">
        <ProcessMultSelect v-model="item.relate_process_set" size="small" @change="handleChange" />
      </template>
      <template slot="waiting_time" slot-scope="value, item">
        <a-input-number v-model="item.waiting_time" :min="0" size="small" style="width: 100%" @change="handleChange" />
      </template>
      <template slot="remark" slot-scope="value, item">
        <a-input v-model="item.remark" size="small" @change="handleChange" />
      </template>

      <template slot="action" slot-scope="value, item, index">
        <a-button-group>
          <a-button-group size="small">
            <a-button icon="up" :disabled="index === 0" @click="upItem(index)" />
            <a-button icon="down" :disabled="index + 1 === dataItems.length" @click="downItem(index)" />
            <a-button type="danger" size="small" @click="removeItem(index)">移除</a-button>
          </a-button-group>
        </a-button-group>
      </template>
      <template slot="footer">
        <a-button-group style="width: 100%">
          <a-button type="dashed" block @click="addItem">添加工序</a-button>
        </a-button-group>
      </template>
    </a-table>
  </div>
</template>

<script>
export default {
  components: {
    ProcessSelect: () => import("@/components/ProcessSelect"),
    ProcessMultSelect: () => import("@/components/ProcessMultSelect"),
  },
  props: ["value"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "工序",
          dataIndex: "process",
          width: 200,
          scopedSlots: { customRender: "process" },
        },
        {
          title: "关键工序",
          dataIndex: "is_critical",
          width: 100,
          scopedSlots: { customRender: "is_critical" },
        },
        {
          title: "关联工序",
          dataIndex: "relate_process_set",
          width: 200,
          scopedSlots: { customRender: "relate_process_set" },
        },
        {
          title: "准备时间",
          dataIndex: "preparation_time",
          width: 100,
          scopedSlots: { customRender: "preparation_time" },
        },
        {
          title: "等待时间",
          dataIndex: "waiting_time",
          width: 100,
          scopedSlots: { customRender: "waiting_time" },
        },
        {
          title: "备注",
          dataIndex: "remark",
          scopedSlots: { customRender: "remark" },
        },
        {
          title: "操作",
          width: 60,
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataItems: [],
    };
  },
  methods: {
    addItem() {
      this.dataItems.push({
        uid: -new Date().getTime(),
        process: undefined,
        process_item: {},
        step_number: this.dataItems.length,
        is_critical: true,
        preparation_time: 0,
        waiting_time: 0,
        remark: null,
        relate_process_set: [],
      });
    },
    upItem(index) {
      if (index !== 0) {
        const dataItems = [...this.dataItems];
        const targetItem = dataItems[index];
        dataItems.splice(index, 1);
        dataItems.splice(index - 1, 0, targetItem);
        this.dataItems = dataItems.map((item, index) => ({ ...item, step_number: index }));
        this.handleChange();
      }
    },
    downItem(index) {
      if (index + 1 < this.dataItems.length) {
        const dataItems = [...this.dataItems];
        const targetItem = dataItems[index];
        dataItems.splice(index, 1);
        dataItems.splice(index + 1, 0, targetItem);
        this.dataItems = dataItems.map((item, index) => ({ ...item, step_number: index }));
        this.handleChange();
      }
    },
    removeItem(index) {
      this.dataItems.splice(index, 1);
      this.handleChange();
    },
    replaceItem(index, item) {
      this.dataItems.splice(index, 1, item);
      this.handleChange();
    },
    changeGoods(index, item) {
      this.dataItems[index].goods_item = item;
      this.handleChange();
    },
    handleChange() {
      this.$emit("change", this.dataItems);
    },
  },
  mounted() {
    this.dataItems = this.value.map((item) => ({ uid: item.id, ...item }));
    this.dataItems.sort((a, b) => a.step_number - b.step_number);
  },
};
</script>

<style scoped></style>
